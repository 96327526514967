export function createViewedEvents(_ref) {
  var items = _ref.items;
  var objectIDsByIndexName = items.reduce(function (acc, current) {
    var _acc$current$__autoco;

    acc[current.__autocomplete_indexName] = ((_acc$current$__autoco = acc[current.__autocomplete_indexName]) !== null && _acc$current$__autoco !== void 0 ? _acc$current$__autoco : []).concat(current.objectID);
    return acc;
  }, {});
  return Object.keys(objectIDsByIndexName).map(function (indexName) {
    var objectIDs = objectIDsByIndexName[indexName];
    return {
      index: indexName,
      objectIDs: objectIDs
    };
  });
}