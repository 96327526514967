export function createClickedEvent(_ref) {
  var item = _ref.item,
      items = _ref.items;
  return {
    index: item.__autocomplete_indexName,
    objectIDs: [item.objectID],
    positions: [1 + items.findIndex(function (x) {
      return x.objectID === item.objectID;
    })],
    queryID: item.__autocomplete_queryID
  };
}